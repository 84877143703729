<template>
    <div>
        <h4 class="bold text-uppercase mb-3">
            <span @click="$router.go(-1)" class="font-weight-bold hand">
                <i class="fas fa-chevron-left mx-1"></i>
                {{ $route.name }}
            </span>
        </h4>
        <div class="row mt-3">
            <div class="col-md-8 col-12">
                <div class="card card-gray">
                    <div class="card-body">
                        <div class="form-group row">
                            <label class="col-sm-4 col-form-label font-bold">Số điện thoại liên hệ:</label>
                            <div class="col-sm-8">
                                <input
                                    type="text"
                                    placeholder="Nhập số điện thoại của bạn"
                                    class="form-control input-light"
                                    v-model="siteUpdate.mobile_phone"
                                />
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-sm-4 col-form-label font-bold">Facebook liên hệ:</label>
                            <div class="col-sm-8">
                                <input
                                    type="text"
                                    placeholder="Nhập Facebook của bạn"
                                    class="form-control input-light"
                                    v-model="siteUpdate.facebook"
                                />
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-sm-4 col-form-label font-bold">Link Facebook chat:</label>
                            <div class="col-sm-8">
                                <input
                                    type="text"
                                    placeholder="Nhập Facebook chat của bạn"
                                    class="form-control input-light"
                                    v-model="siteUpdate.fb_chat"
                                />
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-sm-4 col-form-label font-bold">Link đến trang hướng dẫn:</label>
                            <div class="col-sm-8">
                                <input
                                    type="text"
                                    placeholder="Nhập link trang hướng dẫn của bạn"
                                    class="form-control input-light"
                                    v-model="siteUpdate.site_guide"
                                />
                                <button
                                    @click="updateSiteInfo()"
                                    class="btn form-control btn-lg bold btn-dark-blue form-control mt-3"
                                    type="submit"
                                >
                                    Lưu thông tin
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-4 col-12">
                <div class="card card-gray-2 mb-3">
                    <div class="card-body">
                        <div class="">
                            <h6>
                                <i class="fa fa-info mr-1"></i>
                                Bạn có thể chỉnh sửa các thông tin liên hệ của trang.
                            </h6>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { updateSiteInfo } from "../../../api/admin-agency-lv2"
import { catchError } from "../../../helpers"
export default {
    name: "site-price",
    components: {},
    data() {
        return {
            siteUpdate: {}
        }
    },
    computed: {
        user() {
            return this.$store.state.user.user
        },
        tools() {
            return this.$store.state.tools.tools
        },
        reload() {
            return this.$store.state.app.reload
        },
        currency() {
            return this.$store.state.agency.site.currency || "Xu"
        },
        site() {
            return this.$store.state.agency.site
        },
        agency() {
            return this.$store.state.agency
        }
    },
    created() {
        this.siteUpdate = Object.assign({}, this.site)
    },
    methods: {
        updateSiteInfo: async function(reset = false) {
            if (this.siteUpdate.config && this.siteUpdate.config.guide) {
                let text = this.siteUpdate.config.guide
                if (!text.includes("http://") && !text.includes("https://")) {
                    text = "http://" + text
                }
                this.siteUpdate.config.guide = text
            }
            let cancel = false
            if (reset) {
                await this.$swal({
                    title: "Xác nhận",
                    html:
                        "Sau khi khôi phục cài đặt bạn sẽ phải cài lại giao diện, nội dung chuyển khoản và Facebook hỗ trợ.</br></br>Bạn có chắc chắn muốn khôi phục cài đặt gốc?",
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Đồng Ý",
                    cancelButtonText: "Không",
                    reverseButtons: true
                }).then(result => {
                    if (result.value) {
                        this.siteUpdate.config = null
                    } else {
                        cancel = true
                    }
                })
            }
            if (cancel) {
                return
            }
            let data = await updateSiteInfo(this.siteUpdate)
            if (data.status === 200 && data.success) {
                this.$swal("Thành Công", data.message, "success")
                this.$store.dispatch("GET_SITE_AGENCY_INFO", this.agency.agency_domain)
            } else this.$swal("Lỗi", catchError(data), "error")
        }
    }
}
</script>
